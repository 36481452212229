<template>
  <div v-if="visible">
    <el-table :data="tableData" style="width: 100%" v-loading="loading" element-loading-text="加载中">
      <el-table-column prop="deviceTitle" label="设备名称" width="180"></el-table-column>

      <el-table-column prop="status" label="状态" width="180"></el-table-column>

      <el-table-column prop="time" label="延时自动恢复布防" width="180">
        <template slot-scope="scope">
          <span @click="dialogVisible = true">
            {{ scope.row.time }}
            <i class="el-icon-arrow-right"></i>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <p>在布防状态下可进行报警联动，比如IO输出和短信报警等</p>
    <p>撤防后会禁用所有的报警联动和短信</p>

    <div class="btn">
      <el-button type="success" size="small" @click="onSubmit">布防</el-button>
      <el-button size="small" type="danger" @click="cancelSubmit">撤防</el-button>
    </div>

    <el-dialog title="自动恢复布防" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <span>撤防后多少分钟自动开启布防(0为禁止)</span>
      <el-input v-model="time" placeholder="请输入内容"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="modalSure">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      visible: true,
      time: '',
      uid: '',
      devices: [],
      tableData: [{
        status: "",
        time: '',
        deviceTitle: ''
      }],
      loading: true,
      timeCount: 0   //定时器
    }
  },
  created() {
    if (this.$store.state.treeData.length) {
      let obj = this.$store.state.treeData[0].children
      let path = '全部设备' + ' > ' + obj[0].title + ' > ' + obj[0].children[0].title

      setTimeout(() => {
        this.$parent.$parent.setNodePath(path);
      }, 500)
      let first = obj[0].children[0]
      if (first.uid == undefined) {   //防止设备树层级过多
        first = obj[0].children[0].children[0]
      }
      this.uid = first.uid
      let { uid, title } = first;
      this.tableData[0].deviceTitle = title ? title : uid;
      // this.getFieldsData(model)
      this.getData(uid)
    }
  },
  watch: {
    '$store.state.curTreeData': {
      handler(val) {
        if (val[0].type == "device") {
          this.loading = true
          this.setLoadingtimeOut("start")//开始计时
          let [{ uid, title }] = val;
          this.tableData[0].status = ""
          this.tableData[0].time = ''
          this.tableData[0].deviceTitle = title ? title : uid;
          this.getData(uid)
          this.uid = uid
        }
      }
    }
  },
  mounted() {
    this.ws.addCallback('devicesAck', (res) => {
      this.devices = res.devices
      this.setHotDevices(this.devices)

      let obj = this.$store.state.treeData[0].children

      let path = '全部设备' + ' > ' + obj[0].title + ' > ' + obj[0].children[0].title
      this.$parent.$parent.setNodePath(path);

      let first = obj[0].children[0]
      if (first.uid == undefined) {   //防止设备树层级过多
        first = obj[0].children[0].children[0]
      }
      this.uid = first.uid
      let { uid, title } = first;
      this.tableData[0].deviceTitle = title ? title : uid;


      this.getData(uid)
    })

    this.ws.addCallback('armConfigAck', (res) => {
      let time = res.disarmTimeout / 60 || ''
      this.time = time
      this.tableData[0].status = res.armed == "1" ? "已布防" : "已撤防";
      this.tableData[0].time = this.disarmTimeout2Desc(time)
      this.setLoadingtimeOut("close")
      this.loading = false

    })

    this.ws.addCallback('disarmAck', (res) => {
      console.log(res)
      this.$message({
        showClose: true,
        message: '撤防成功!',
        type: 'success'
      });
      this.getData()
    })

    this.ws.addCallback('armAck', (res) => {
      this.$message('布防成功!')
      this.getData()
    })

  },
  methods: {
    // 设置热点设备
    setHotDevices(arr) {
      let uids = "";
      arr.forEach((item) => {
        uids += item.id + ","
      })
      let json = {
        "cmd": "setHotDevices",
        "uids": uids
      }
      this.ws.send(json)
    },

    modalSure() {
      let minute = parseInt(this.time)

      const maxMinute = 24 * 60 * 10
      if (minute < 0) {
        minute = 0
      } else if (minute > maxMinute) {
        minute = maxMinute
      }

      this.tableData[0].time = this.disarmTimeout2Desc(minute)

      let json = {
        "cmd": "setArmConfig",
        "uid": this.uid,
        "disarmTimeout": minute * 60
      };
      this.ws.send(json)

      this.ws.addCallback("setArmConfigAck", (res) => {
        this.$message('配置成功,请点击下方布防才能生效！');
        this.dialogVisible = false
      })

    },
    handleClose(done) {
      done();
    },
    getData(val) {
      let json = {
        "cmd": "armConfig",
        "uid": val || this.uid
      }
      this.ws.send(json);
    },

    cancelSubmit() {
      let json = {
        "cmd": "disarm",
        "uid": this.uid
      };
      this.ws.send(json);

    },
    onSubmit() {
      let json = {
        "cmd": "arm",
        "uid": this.uid
      };
      this.ws.send(json);

    },
    disarmTimeout2Desc(minute) {
      if (minute == 0) {
        return "禁用自动恢复"
      }

      let hours = Math.floor(minute / 60);
      if (hours == 0) {
        return minute + "分钟"
      }

      minute -= hours * 60;
      if (minute > 0) {
        return hours + " 小时 " + minute + " 分"
      }
      return hours + " 小时"
    },
    setLoadingtimeOut(val) {
      let state = ""
      clearTimeout(this.timeCount);
      if (val == "start") {
        state = setTimeout(() => {
          if (this.loading) {
            this.common.cToast(this, "获取数据失败，请刷新后重试");
            this.loading = false
          }
        }, 5000)
        this.timeCount = state
      } else {
        clearTimeout(state);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.btn {
  margin-top: 30px;
}
</style>